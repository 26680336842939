

.control-prev{
    background-color: rgba(0,0,0,0.8) !important;
    width:19.8% !important;
    height: 100% !important;
}




.control-next{
    background-color: rgba(0,0,0,0.8) !important;
    width:19.8% !important;
}




/* .control-next:hover{
    background: rgba(0,0,0,0) !important;
    margin-left: 10%!important;
    width:19.8%;
} */

.carousel.carousel-slider .control-arrow:hover {
    transition: all .4s ease;
    background: rgba(0,0,0,0) !important;
}






.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    transition: all .4s ease;
    opacity: 1.0 !important;
    position: absolute;
    z-index: 2;
    background: none;
    border: 0;
    font-size: 18px!important;
    cursor: pointer;
    background-color: rgba(0,0,0,0.75) !important;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
    margin-left: 0px!important;
}



.carousel .control-next.control-arrow::before {
    border-right: 0px solid #fff ;
    content: '→'!important;
    margin-right: 250px;
}

.carousel .control-next:hover.control-arrow::before {
    border-right: 0px solid #fff ;
    transition: all .4s ease-in;
    content: '→'!important;
    margin-right: 0px;
}


.carousel .control-prev.control-arrow::after {
    border-right: 0px solid #fff;
    content: '←'!important;
    margin-left: 250px !important;
}


.carousel .control-prev:hover.control-arrow::after {
    border-right: 0px solid #fff;
    transition: all .4s ease;
    content: '←'!important;
    margin-left: 0px !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 0px solid #fff !important;
}

@media (max-width: 592px) {
    .carousel .control-prev.control-arrow::after {
        border-right: 0px solid #fff;
        content: '←'!important;
        margin-left: 0px !important;
      }
}
