.heightimg{
    height: 100%;
    width: 60% !important;
}

.img-book-mobile{
    
}


/* .decboo5-video{
object-fit: cover;
} */

.padding-slides {
    padding-right: 2rem;
    padding-left: 1.5rem;
}

@media (max-width: 592px) {
    .heightimg{
        height: 100%;
        width: 100% !important;
    }
    .padding-slides {
        padding-right: 2rem;
        padding-left: 2rem;
    }
  }