body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", Avenir,
    monospace;
}

@font-face {
  font-family: "AvenirBlack";
  src: local("AvenirBlack"),
    url("./fonts/AvenirLTStd-Black.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "AvenirBook";
  src: local("AvenirBook"),
    url("./fonts/AvenirLTStd-Book.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "AvenirRoman";
  src: local("AvenirRoman"),
    url("./fonts/AvenirLTStd-Roman.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"),
    url("./fonts/CenturyGothic.ttf") format("truetype");
  font-weight: normal;
}
